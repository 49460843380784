import { _queryStringify } from './../utils/xhr.js';
import handleResponse from './utils.js';


export var getEntities = function(params) {
    const requestOptions = {
      method: 'GET'
    };

    var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-entities?${_queryStringify(params)}`

    return fetch(url, requestOptions)
      .then(handleResponse)
      .then(entities => {
        return entities;
      })
    .catch(e => {
      console.log("something went wrong")
    });
}


export var getWaterbodies = function(params) {
  const requestOptions = {
    method: 'GET'
  };

  var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-water-bodies-with-type?${_queryStringify(params)}`

  return fetch(url, requestOptions)
    .then(handleResponse) 
    .then(water_bodies => {
      return water_bodies;
    })
    .catch(e => {
      console.log("something went wrong")
    });
}


export var getLatestMapValues = function(params) {
  const requestOptions = {
    method: 'GET'
  };

  var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-latest-map-values?${_queryStringify(params)}`

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(map_values => {
      return map_values;
    })
    .catch(e => {
      console.log("something went wrong")
    });
}

export var getWaterBodyDetails = function(params) {
  const requestOptions = {
    method: 'GET'
  };

  var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-water-body-details?${_queryStringify(params)}`

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(water_body_details => {
      return water_body_details;
    })
    .catch(e => {
      console.log("something went wrong")
    });
}

export var getEntityVerticalValues = function(params) {
  const requestOptions = {
    method: 'GET'
  };

  var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-view-vertical-values?${_queryStringify(params)}`

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(map_values => {
      return map_values;
    })
    .catch(e => {
      console.log("something went wrong")
    });
}

export var getEntityCoordinates = function(params) {
  const requestOptions = {
    method: 'GET'
  };

  var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-entity-coordinates?${_queryStringify(params)}`

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(map_values => {
      return map_values;
    })
    .catch(e => {
      console.log("something went wrong")
    });
}


export var getVerticalImages = function(params) {
  const requestOptions = {
    method: 'GET'
  };

  var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-vertical-images?${_queryStringify(params)}`

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(map_values => {
      return map_values;
    })
    .catch(e => {
      console.log("something went wrong")
    });
}

export var getGalleryImages = function(params) {
  const requestOptions = {
    method: 'GET'
  };

  var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-dynamic-gallery-images?${_queryStringify(params)}`

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(map_values => {
      return map_values;
    })
    .catch(e => {
      console.log("something went wrong")
    });
}

export var getMetabaseIframeUrl = function(params) {
  const requestOptions = {
    method: 'GET'
  };
  
  var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/get-metabase-iframe-url?${_queryStringify(params)}`

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(url => {
      return url;
    })
    .catch(e => {
      console.log("something went wrong")
    });
}
