function handleResponse(response) {
    return response.text().then(text => {
      if (!response.ok) {
        let error;
        if (response.status === 401) {
          // auto logout if 401 response returned from api
        //   userLogout();
          return Promise.reject("Session timeout");
          //location.reload(true);
        }
        if (response.status === 500 || response.status === 404) {
          error = "Something went wrong! Please try again after some time";
        } else {
          error = JSON.parse(text)["error"];
        }
  
        return Promise.reject(error);
      }
  
      const data = text && JSON.parse(text);
      return data;
    });
  }
  
export default handleResponse;