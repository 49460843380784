import React, { Component } from 'react';
import RoomIcon from '@material-ui/icons/Room';
import GoogleMapReact from 'google-map-react';
import Tooltip from '@material-ui/core/Tooltip';
// import Link from '../components/Link';


class MapCard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      contourToggle: true,
      orthoToggle: true,
      shapesToggle: true,
    }
    this.mapRef = React.createRef();
    this._renderWaterBodyMarkers = this._renderWaterBodyMarkers.bind(this);
    this.handleApiLoaded = this.handleApiLoaded.bind(this)
    this.setContourToggle = this.setContourToggle.bind(this)
  }

  componentDidMount(){
  }

  setContourToggle = (contourToggle) => {
    this.setState({contourToggle})
  }

  setOrthoToggle = (orthoToggle) => {
    this.setState({orthoToggle})
  }

  setShapesToggle = (shapesToggle) => {
    this.setState({shapesToggle})
  }

  onMapLoad = (map, geoJsonFilePath) => {
    map.data.loadGeoJson(geoJsonFilePath)
  }

  onClick = (...args) => {
  console.log('onClick args: ', args[0].latLng.lat(), ' : ', args[0].latLng.lng())
  }

  onDataLoad = data => {
  console.log('data: ', data)
  }

  dataOptions = {
      controlPosition: 'TOP_LEFT',
      controls: ['Point'],
      drawingMode: 'Point', //  "LineString" or "Polygon".
      featureFactory: geometry => {
          console.log('geometry: ', geometry)
      },
      // Type:  boolean
      // If true, the marker receives mouse and touch events. Default value is true.
      clickable: true,

      // Type:  string
      // Mouse cursor to show on hover. Only applies to point geometries.
      // cursor: 'cursor',

      // Type:  boolean
      // If true, the object can be dragged across the map and the underlying feature will have its geometry updated. Default value is false.
      draggable: true,

      // Type:  boolean
      // If true, the object can be edited by dragging control points and the underlying feature will have its geometry updated. Only applies to LineString and Polygon geometries. Default value is false.
      editable: false,

      // Type:  string
      // The fill color. All CSS3 colors are supported except for extended named colors. Only applies to polygon geometries.
      fillColor: '#F05',

      // Type:  number
      // The fill opacity between 0.0 and 1.0. Only applies to polygon geometries.
      fillOpacity: 1,

      // Type:  string|Icon|Symbol
      // Icon for the foreground. If a string is provided, it is treated as though it were an Icon with the string as url. Only applies to point geometries.
      // icon: 'icon',

      // Type:  MarkerShape
      // Defines the image map used for hit detection. Only applies to point geometries.
      // shape: 'shape',

      // Type:  string
      // The stroke color. All CSS3 colors are supported except for extended named colors. Only applies to line and polygon geometries.
      strokeColor: '#00FF55',

      // Type:  number
      // The stroke opacity between 0.0 and 1.0. Only applies to line and polygon geometries.
      strokeOpacity: 1,

      // Type:  number
      // The stroke width in pixels. Only applies to line and polygon geometries.
      strokeWeight: 2,

      // Type:  string
      // Rollover text. Only applies to point geometries.
      title: 'Title',

      // Type:  boolean
      // Whether the feature is visible. Defaults to true.
      visible: true,

      // Type:  number
      // All features are displayed on the map in order of their zIndex, with higher values displaying in front of features with lower values. Markers are always displayed in front of line-strings and polygons.
      zIndex: 2
  }


// Marker component

  _renderWaterBodyMarkers() {
    const markerStyle = {
      //border: '1px solid white',
      //borderRadius: '50%',
      height: 20,
      width: 20,
      //backgroundColor: 'blue',  
      cursor: 'pointer',
      zIndex: 10,
    };
    // const Marker = () => {
    //   return (
    //     <div style={markerStyle} />
    //   );
    // };
    // var Marker = ({ url }) => <div><img style={markerStyle} src={url} color="red"></img></div>;
    var Marker = ({ element }) => {
      let color = 'red'
      if (element.type === 'Healthy'){
        color = 'blue';
      }
      else if (element.type === 'Eutrophication'){
        color = 'black';
      } 
      else{
        color = 'red'
      }
      const toolTipText = <div>
          <div>Unique ID: <b>{element.name}</b></div>
          <div>State: <b>{element.state}</b></div>
          <div>District: <b>{element.district}</b></div>
          <div>Block: <b>{element.block}</b></div>
          <div>Village: <b>{element.village}</b></div>
          <div>Type: <b>{element.type}</b></div>
          <div>Score: <b>{element.score}</b></div>
          <div>Cleanliness Index: <b>{element.index}</b></div>
        </div>
      return (<Tooltip title={toolTipText}>
        {/* <Link to={"/water-body/"+ element.water_body_id}> */}
          <RoomIcon style={{height: 20, width: 20, cursor: 'pointer', zIndex: 10, color: color}} 
            onClick={() => this.props.setWaterBodyValue(element) }
          />
        {/* </Link> */}
      </Tooltip>)
    }
    if (this.props.waterBodies
        && this.props.waterBodies.length > 0
        && this.props.waterBodies[0]
        && (
          !this.props.waterBodyMapDetails || 
          (this.props.waterBodyMapDetails && !this.props.waterBodyMapDetails.image_bounds['south_west'])
          )
        ) {
      return this.props.waterBodies.map(wb => (
        <Marker
          key={wb["name"]}
          lat={wb['latitude']}
          lng={wb['longitude']}
          element={wb}
          label={wb['name']}
        />
      ))
    }
    return null
  }

  autoCenterMap = ({ google }, map) => {
    this.loadGeoJson(map);
  }

  loadGeoJson = async (map) => {
    if (this.props.waterBodyMapDetails && this.props.waterBodyMapDetails.geo_json_list) {
      this.props.waterBodyMapDetails.geo_json_list.map(geojson => map.data.addGeoJson(geojson))
    }
    // map.data.addGeoJson(geojsonRoutes); // # load geojson layer
  }

  getColorForShapeType = (type) => {
    if(type.toUpperCase() === 'ROAD'){
      return 'gray'
    }
    else if(type.toUpperCase() === 'BUILDING'){
      return 'red'
    }
    else if(type.toUpperCase() === 'FARMLAND'){
      return 'lightgreen'
    }
    else if(type.toUpperCase() === 'TREES' || type === 'TREE'){
      return 'green'
    }
    else if(type.toUpperCase() === 'WATERBODY'){
      return 'blue'
    }
    else {
      return 'black'
    }
  }

  handleApiLoaded = (map, maps) => {
    let thisObj = this
    if (this.props.waterBodyMapDetails) {
      // const mapDisplay = maps.Map(this.mapRef, {
      //   zoom: 12,
      //   center: {lat: this.props.waterBody?.latitude, lng: this.props.waterBody?.longitude}
      // });
      // const toggleButton = document.createElement("button");
      // console.log("djkfnsdf")
      // console.log(this.state.contourToggle)
      // toggleButton.textContent = "Toggle Contour";
      // toggleButton.classList.add("custom-map-control-button");
      // toggleButton.addEventListener("click", () => {
      //   this.setState({contourToggle: !this.state.contourToggle});
      // });
      // map.controls[maps.ControlPosition.TOP_RIGHT].push(toggleButton);

      // map.data.forEach(function(feature) {
      //   // filter...
      //   console.log(feature)
      //   map.data.remove(feature);
      // });

      map.data.setStyle(function(feature) {
        var name = feature.getProperty('name') || feature.getProperty('NAME');
        var color = thisObj.getColorForShapeType(name);
        return {
          fillColor: color,
          strokeWeight: 1
        };
      });
      // map.data.loadGeoJson("http://localhost:9000/v1/api/get-dummy-geo-json")
      if (this.props.waterBodyMapDetails
          && this.props.waterBodyMapDetails.other_geo_json_list
          && this.props.shapesToggle) {

        this.props.waterBodyMapDetails.other_geo_json_list.map(geojson => {
          map.data.loadGeoJson(geojson);
        })
      }
      if (this.props.waterBodyMapDetails
          && this.props.waterBodyMapDetails.contour_geo_json_list
          && this.props.contourToggle) {
        this.props.waterBodyMapDetails.contour_geo_json_list.map(geojson => {
          map.data.loadGeoJson(geojson);
        })
      }
      if (this.props.waterBodyMapDetails
          && this.props.waterBodyMapDetails.image_url
          && this.props.waterBodyMapDetails.image_bounds
          && this.props.orthoToggle) {
        const image = this.props.waterBodyMapDetails.image_url
        const southWest = this.props.waterBodyMapDetails.image_bounds['south_west']
        const northEast = this.props.waterBodyMapDetails.image_bounds['north_east']
        const bounds = new maps.LatLngBounds(
          new maps.LatLng(southWest[0], southWest[1]), // 25.3223217565575, 87.1162486531136
          new maps.LatLng(northEast[0], northEast[1]), // 25.3214779744756, 87.1153864097482

          // new maps.LatLng(25.59949500107108, 84.01911204345373), // 25.3214779744756, 87.1153864097482
          // new maps.LatLng(25.601061492822794, 84.02085708076297) // 25.3223217565575, 87.1162486531136


        );
        const imageOverlay = Object.assign(new maps.OverlayView(), {
          onAdd() {
            this.div = document.createElement("div");
            this.div.style.borderStyle = "none";
            this.div.style.borderWidth = "0px";
            this.div.style.position = "absolute";
            // Create the img element and attach it to the div.
            const img = document.createElement("img");
            img.src = image;
            img.style.width = "100%";
            img.style.height = "100%";
            // img.style.position = "absolute";
            this.div.appendChild(img);
            // Add the element to the "overlayLayer" pane.
            const panes = this.getPanes();
            panes.overlayLayer.appendChild(this.div);
          },
          draw() {
            // We use the south-west and north-east
            // coordinates of the overlay to peg it to the correct position and size.
            // To do this, we need to retrieve the projection from the overlay.
            const overlayProjection = this.getProjection();
            // Retrieve the south-west and north-east coordinates of this overlay
            // in LatLngs and convert them to pixel coordinates.
            // We'll use these coordinates to resize the div.
            const sw = overlayProjection.fromLatLngToDivPixel(
              bounds.getSouthWest()
            );
            const ne = overlayProjection.fromLatLngToDivPixel(
              bounds.getNorthEast()
            );

            // Resize the image's div to fit the indicated dimensions.
            if (this.div) {
              this.div.style.left = sw.x + "px";
              this.div.style.top = ne.y + "px";
              this.div.style.width = ne.x - sw.x + "px";
              this.div.style.height = sw.y - ne.y + "px";
            }
          },
          /**
           * The onRemove() method will be called automatically from the API if
           * we ever set the overlay's map property to 'null'.
           */
          onRemove() {
            if (this.div) {
              this.div.parentNode.removeChild(this.div);
              delete this.div;
            }
          },
          /**
           *  Set the visibility to 'hidden' or 'visible'.
           */
          hide() {
            if (this.div) {
              this.div.style.visibility = "hidden";
            }
          },
          show() {
            if (this.div) {
              this.div.style.visibility = "visible";
            }
          },
          toggle() {
            if (this.div) {
              if (this.div.style.visibility === "hidden") {
                this.show();
              } else {
                this.hide();
              }
            }
          },
          toggleDOM(map) {
            if (this.getMap()) {
              this.setMap(null);
            } else {
              this.setMap(map);
            }
          },
        })
        imageOverlay.setMap(map)
      }
    }
  };

  _renderWaterBodyControls () {
    return null
  }

  getMapOptions = (maps) => {

    return {
        streetViewControl: false,
        scaleControl: true,
        fullscreenControl: false,
        styles: [{
            featureType: "poi.business",
            elementType: "labels",
            stylers: [{
                visibility: "off"
            }]
        }],
        scrollwheel: false,
        gestureHandling: "greedy",
        draggable: true,
        // disableDoubleClickZoom: true,

        mapTypeControl: true,
        mapTypeId: maps.MapTypeId.MAP,
        mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.BOTTOM_CENTER,
            mapTypeIds: [
                maps.MapTypeId.ROADMAP,
                maps.MapTypeId.SATELLITE,
                maps.MapTypeId.HYBRID
            ]
        },

        zoomControl: true,
        clickableIcons: false
    };
  }

  render() {
        // const bounds = new window.google.maps.LatLngBounds(
        //     new window.google.maps.LatLng(25.3214779744756, 87.1153864097482),
        //     new window.google.maps.LatLng(25.3223217565575, 87.1162486531136)
    // );

    // let mapCenter = this.props.entity ? mapCoordinates[this.props.entity]?.location : [25.599842636124436, 76.512008852755756]
    // let mapZoom = mapCoordinates[this.props.entity]?.zoom || 8
    let location = this.props.entityCoordinates ? [this.props.entityCoordinates['latitude'], this.props.entityCoordinates['longitude']] : null

    let mapCenter = location ? location : [25.599842636124436, 76.512008852755756]
    let mapZoom = this.props.entityCoordinates?.zoom || 8

    if (this.props.waterBody
        && this.props.waterBody.latitude
        && this.props.waterBody.longitude) {
      if(this.props.waterBodyMapDetails?.image_bounds['south_west']){
        const southWest = this.props.waterBodyMapDetails.image_bounds['south_west']
        const northEast = this.props.waterBodyMapDetails.image_bounds['north_east']

        let lat = (southWest[0] + northEast[0]) / 2
        let lng = (southWest[1] + northEast[1]) / 2
        mapCenter = [lat, lng]
      }
      else{
        mapCenter = [this.props.waterBody.latitude, this.props.waterBody.longitude]
      }
      mapZoom = 18
    }

    return (
      <div style={{ height: this.props.height, width: '100%' }}>
        {this.props.waterBodies && this.props.waterBodies.length > 0 && (
          <GoogleMapReact
            key={(mapCenter) + (this.props.contourToggle ? "contour" : "") + (this.props.orthoToggle ? "ortho" : "") + (this.props.shapesToggle ? "shape" : "")}
            center={mapCenter}
            bootstrapURLKeys={{ key:  "AIzaSyB0RqaibzoXckHpx_tZVuGRb5oubmn7N3s"}}
            defaultZoom={mapZoom}
            onReady={this.autoCenterMap}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
            options={this.getMapOptions}
            >
            {this._renderWaterBodyMarkers()}
            {this._renderWaterBodyControls()}
          </GoogleMapReact>
        )
        }
      </div>
    )
    }
}

export default MapCard
