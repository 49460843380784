import React, { useEffect, useState } from 'react';
import {getMetabaseIframeUrl} from '../services/entity';
import Spinner from "../shared/Spinner";
import IframeResizer from 'iframe-resizer-react'


export default function MetabaseDashboard({entityType, state, district, block, village, waterBody, dashboard, height}) {
  const [loading, setLoading] = useState(false);
  const [iframeUrl, setIframeUrl] = useState(null);

  const getParamsBasedOnEntityType = (entityType) => {
    // Encode in classical base64
    if(entityType === "country"){
      return {}
    }
    else if(entityType === "state"){
      return {state: state}
    }
    else if(entityType === "district"){
      return {district: district}
    }
    else if(entityType === "block"){
      return {block: block}
    }
    else if(entityType === "village"){
      return {village: village}
    }
    else{
      return {water_body: waterBody}
    }
  }

  useEffect(() => {
    let params = getParamsBasedOnEntityType(entityType)
    params['number'] = dashboard
    params['type'] = "dashboard"
    setLoading(true)
    getMetabaseIframeUrl(params).then(resp => setIframeUrl(resp))
  }, [entityType, state, district, block, village, waterBody]);

  return (
      <div>
        {loading && <Spinner />}
        <IframeResizer
            key={iframeUrl}
            heightCalculationMethod="bodyScroll"
            style={{ width: '1px', minWidth: '100%', border: "0", minHeight: height}}
            src={iframeUrl}
            onResized={()=> setLoading(false)}
            checkOrigin={false}
        />
    </div>
  );
}
